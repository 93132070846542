import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tips--tricks"
    }}>{`Tips & tricks`}</h1>
    <p>{`Discover some handy tools that staff can use to help with common tasks.`}</p>
    <h2 {...{
      "id": "magic-wand-for-staff-booking"
    }}>{`Magic wand for staff booking`}</h2>
    <p>{`Easily complete a booking with all the member information, if you've already booked the member in. This is a useful tool for people wanting to book into multiple events.`}</p>
    <ol>
      <li parentName="ol">{`Select the Event`}</li>
      <li parentName="ol">{`Click on the magic wand and all member information from the previous booking will appear, except the card number`}</li>
      <li parentName="ol">{`Re-enter the library card number (if required) and confirm the booking`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">The magic wand appears in many other places, it just makes life easier</Alert>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/IXGwy7_EyC0" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "downloading--printing-flyers"
    }}>{`Downloading & Printing Flyers`}</h2>
    <ol>
      <li parentName="ol">{`On the staff booking page, select the icon on the right hand side that looks like a flyer to view event flyer`}</li>
      <li parentName="ol">{`An automatically generated flyer can be saved or printed`}</li>
      <li parentName="ol">{`Printing instructions are provided, you will need to configure various settings to ensure successful printing`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-1"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/XN-Yh_nDOXo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "check-in-attendees"
    }}>{`Check in Attendees`}</h2>
    <ol>
      <li parentName="ol">{`On the staff bookings page, locate current bookings and select the printer icon to view & print attendees`}</li>
      <li parentName="ol">{`This will load a page with current bookings and the option to mark people as checked in as they arrive at an event`}</li>
      <li parentName="ol">{`The check in status is memorised, so staff can go back and look at who was checked into an event at any time`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-2"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/eWDSdjNu0Cs" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">The check in feature is helpful for staff using multiple devices to check in attendees, this is automatically synced</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      